@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #ee3622;
  --primary-dark-color: #cd2210;

  --grey-color: #ccc;
  --grey-light-color: #f7f7f7;
  --grey-dark-color: #888;

  --black-color: #080808;
  --white-color: #fff;

  --fb-color: #4867aa;
}
.container-fluid {
  @apply px-4 md:px-6 lg:px-10 w-full mx-auto;
}
.btn {
  @apply py-1.5 px-3 transition  text-center font-light border border-transparent disabled:opacity-70 disabled:cursor-default;
}
.btn:not(.rounded-btn) {
  @apply rounded;
}
.btn.rounded-btn {
  @apply rounded-full;
}
.btn-sm {
  @apply py-1 px-2 rounded transition text-sm text-center font-light border border-transparent;
}
.btn-primary {
  @apply bg-app-primary hover:bg-app-primary-dark text-white;
}
.btn-dark {
  @apply bg-gray-700 hover:bg-gray-800 text-white;
}
.btn-outline-dark {
  @apply border-gray-700 hover:bg-gray-700 hover:text-white text-gray-700;
}
.btn-outline-primary {
  @apply border-app-primary hover:bg-app-primary hover:text-white text-app-primary;
}
.btn-outline-light {
  @apply border-white hover:bg-white hover:bg-opacity-10;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--grey-light-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--grey-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--grey-dark-color);
}

/* Slider */
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 0.8 !important;
}
.carousel .control-next.control-arrow::before {
  border-left-color: #fff !important;
  border-left-width: 10px !important;
}
.carousel .control-prev.control-arrow::before {
  border-right-color: #fff !important;
  border-right-width: 10px !important;
}
.carousel.carousel-slider .control-arrow {
  background-color: rgba(0, 0, 0, 0.06) !important;
}
.carousel.carousel-slider .control-arrow:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
.slider-image {
  width: 100%;
}
@media (min-width: 768px) {
  .slider-image {
    width: unset !important;
  }
}
/* Spinner */
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  @apply flex items-center justify-center;
}
.truncate-3 {
  display: -webkit-box;
  /* display: inline-block; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
